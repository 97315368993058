<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <p
          class="text-h5 mb-0"
        >
          Web-Treter
        </p>
        <v-divider
          class="mb-2"
        ></v-divider>
        <p
          class="font-italic"
        >
          Vorläufiger Projektname eines Tablet-gestützten Tools zum Weben am Louet Spring 2.
        </p>
        <p
          class="text-h6 mt-4 mb-0"
        >
          Die Software
        </p>
        <v-divider
          class="mb-2"
        ></v-divider>
        <p>
          Um Webfehler (zum Beispiel durch falsche Trittfolge oder fehlerbehaftete Bestückung der
          Kettfäden) zu reduzieren, habe ich eine Software entwickelt, die mich bei den verschieden
          Schritten des Webens unterstützen kann. Die Bedienung erfolgt über ein Tablet.
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        Über die Seitenleiste gelangt man zu den verschiedenen Bereichen des WebTreters.
      </v-col>
      <v-col cols="9">
        <v-img
          :src="sidebarImage"
          max-width="512px"
          class="elevation-2"
        ></v-img>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        In der Projektverwaltung können WIF-Dateien für ein Projekt importiert werden und
        zwischen den gespeicherten Projekten gewechselt werden.
      </v-col>
      <v-col cols="9">
        <v-img
          :src="projectsImage"
          max-width="512px"
          class="elevation-2"
        ></v-img>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        Insgesamt stellt die Software vier Assistenten für die unterschiedlichen Arbeitsschritte
        bei der Einrichtung des Webstuhls und des eigentlichen Webens zur Verfügung.
        Im ersten Schritt wird mir angezeigt, wie viele Litzen ich auf den jeweiligen Schäften
        mindestens installieren muss.
      </v-col>
      <v-col cols="9">
        <v-img
          :src="heddleAssistImage"
          max-width="512px"
          class="elevation-2"
        ></v-img>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        Der nächste Assistent unterstützt mich beim Einziehen der Kettfäden. Nach Festlegen
        einer Anzahl Fäden pro Gruppe sagt mir die Software, welche Fäden in welche Schäfte gezogen
        werden müssen und welche Farbe sie haben sollten. Wenn diese Gruppe eingefädelt ist, kann
        ich durch Antippen der Schaltfläche zur nächsten Gruppe springen.
      </v-col>
      <v-col cols="9">
        <v-img
          :src="threadingAssistImage"
          max-width="512px"
          class="elevation-2"
        ></v-img>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        Im nächsten Schritt unterstützt mich die Software beim Anbinden der Tritte. Für jeden Tritt
        zeigt sie an, welche Schäfte oben bzw. unten angebunden werden müssen. Wenn alle Schäfte
        angebunden sind, springt der Assistent durc Antippen der Schaltfläche zum nächsten Tritt.
      </v-col>
      <v-col cols="9">
        <v-img
          :src="tieUpAssistImage"
          max-width="512px"
          class="elevation-2"
        ></v-img>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        Beim anschließenden Weben meldet die Software, wenn ich den richtigen Tritt benutzt habe und
        welche Farben für den Schuß benutzt werden müssen. Der Assistent gibt optional auch akkustische
        Rückmeldung, dass ich korrekt getreten habe, ob ein Farbwechsel ansteht und welcher Tritt als
        nächstes an der Reihe ist.
      </v-col>
      <v-col cols="9">
        <v-img
          :src="weavingAssistOkayImage"
          max-width="512px"
          class="elevation-2"
        ></v-img>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        Sollte ich den falschen Tritt benutzen, wird mir das sowohl optisch als auch akkustisch
        mitgeteilt.
      </v-col>
      <v-col cols="9">
        <v-img
          :src="weavingAssistFailImage"
          max-width="512px"
          class="elevation-2"
        ></v-img>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <p
          class="text-h6 mt-4 mb-0"
        >
          Die Hardware
        </p>
        <v-divider
          class="mb-2"
        ></v-divider>
        <p>
          Um zu erkennen, welcher Tritt gerade benutzt wird, wurden auf der Unterseite der Tritte
          Magneten angebracht. Diese nähern sich beim Treten einem Sensor, der die Information dann
          an eine kleine Steuerbox weitergibt. Diese kommuniziert dann mit dem iPad.
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        Die Sensoren sitzen auf 3D-gedruckten Teilen, die auf die Führungsschiene der Tritte montiert
        werden. Das geht ganz ohne Verschraubung in das Holz des Webstuhls!
      </v-col>
      <v-col cols="9">
        <!-- <v-img
          :src="sensorRenderingImage"
          max-width="512px"
        ></v-img> -->
        <v-img
          :src="sensorRendering2Image"
          max-width="512px"
        ></v-img>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <p>
          Die Steuerbox ist noch ein Prototyp, der aus Komponenten zusammengebaut ist, die in der
          Elektronik-Maker-Szene gerne eingesetzt werden: einem STM32F103-Blue-Pill-Board und einem
          Raspberry Pi. An einer kosten- und platzoptimierten Variante arbeite ich noch.
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import sidebarImage from '@/assets/WebTreter_Sidebar.png';
import projectsImage from '@/assets/WebTreter_Projects.png';
import heddleAssistImage from '@/assets/WebTreter_HeddleAssist.png';
import threadingAssistImage from '@/assets/WebTreter_ThreadingAssist.png';
import tieUpAssistImage from '@/assets/WebTreter_TieUpAssist.png';
import weavingAssistOkayImage from '@/assets/WebTreter_WeavingAssistOkay.png';
import weavingAssistFailImage from '@/assets/WebTreter_WeavingAssistFail.png';
import sensorRenderingImage from '@/assets/WebTreter_SensorRendering.png';
import sensorRendering2Image from '@/assets/WebTreter_SensorRendering2.png';

export default {
  data: () => ({
    sidebarImage,
    projectsImage,
    heddleAssistImage,
    threadingAssistImage,
    tieUpAssistImage,
    weavingAssistOkayImage,
    weavingAssistFailImage,
    sensorRenderingImage,
    sensorRendering2Image,
  }),
};
</script>

<style>

</style>