<template>
  <v-container fluid>
    <div class="text-h3 mb-5">Reed-Substitution-Rechner</div>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>Vorgaben</v-card-title>
          <v-card-text>
            <v-form>
              <v-row>
                <v-col cols="3" class="align-self-center">
                  <v-subheader>Webblatt</v-subheader>
                </v-col>
                <v-col cols="3">
                  <v-text-field v-model="value1" @change="updateResult()"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-select v-model="unit1" @change="updateResult()" :items="units"></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3" class="align-self-center">
                  <v-subheader>Webstück</v-subheader>
                </v-col>
                <v-col cols="3">
                  <v-text-field v-model="value2" @change="updateResult()"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-select v-model="unit2" :items="units" @change="updateResult()"></v-select>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card>
          <v-card-title>Bestes Ergebnis</v-card-title>
          <v-card-text>
            <v-row>
              <v-col>
                <span class="font-weight-bold">Schritte</span>
              </v-col>
              <v-col>
                {{ resultSteps }}
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <span class="font-weight-bold">epi</span>
              </v-col>
              <v-col>
                {{ resultValueEpi }}
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <span class="font-weight-bold">Fäden pro 10 cm</span>
              </v-col>
              <v-col>
                {{ resultValueEpdm }}
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <span class="font-weight-bold">Abweichung</span>
              </v-col>
              <v-col>
                {{ resultDeviation }}
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
const units = [{
  text: 'epi (Fäden pro Inch)',
  value: 'epi',
}, {
  text: 'Fäden pro 10 cm',
  value: 'epdm',
}];

export default {
  data: () => ({
    value1: 40,
    unit1: 'epdm',
    value2: 20,
    unit2: 'epi',
    units,
    resultSteps: '',
    resultValueEpi: 0,
    resultValueEpdm: 0,
    resultDeviation: '',
  }),

  mounted() {
    this.updateResult();
  },

  methods: {
    updateResult() {
      const from = this.normalizeValue(this.value1, this.unit1);
      const to = this.normalizeValue(this.value2, this.unit2);

      let bestResult = null;
      for (let numberOfSteps = 1; numberOfSteps < 5; numberOfSteps++) {
        const stepCount = Math.round(numberOfSteps * to / from);

        const deviation = Math.round(1000 * (stepCount * from) / (numberOfSteps * to)) - 1000;
        const absDeviation = Math.abs(deviation);

        let calculate;
        if (bestResult === null) {
          calculate = true;
        } else if (bestResult.absDeviation > absDeviation) {
          calculate = true;
        }

        if (calculate) {
          const minStep = Math.floor(stepCount / numberOfSteps);
          const maxStep = Math.ceil(stepCount / numberOfSteps);

          const numberOfMaxSteps = stepCount - (minStep * numberOfSteps);
          if ((numberOfMaxSteps < 0) || (numberOfMaxSteps > stepCount)) {
            throw new Error(`Invalid state`);
          }

          const steps = [];
          for (let i = 0; i < numberOfMaxSteps; i++) {
            steps.push(maxStep);
          }
          for (let i = numberOfMaxSteps; i < numberOfSteps; i++) {
            steps.push(minStep);
          }

          steps.sort();

          const result = {
            numberOfSteps,
            stepCount,
            minStep,
            maxStep,
            numberOfMaxSteps,
            steps,
            deviation,
            absDeviation,
          };

          bestResult = result;
        }
      }

      const steps = bestResult.steps;
      const normalizedResult = steps.reduce((memo, step) => memo + step, 0) * from / steps.length;
      const result = this.unnormalizeValue(normalizedResult);

      this.resultSteps = steps.join('-');
      this.resultValueEpi = result.epi.toFixed(1);
      this.resultValueEpdm = result.epdm.toFixed(1);
      this.resultDeviation = `${(bestResult.deviation / 10).toFixed(1)}%`;
    },

    normalizeValue(value, unit) {
      let result;
      if (unit === 'epi') {
        result = value * 1000;
      } else if (unit === 'epdm') {
        result = value * 254;
      } else {
        throw new Error('Unexpected unit');
      }
      return result;
    },

    unnormalizeValue(value) {
      return {
        epi: value / 1000,
        epdm: value / 254,
      };
    },
  },

}
</script>

<style>

</style>