<template>
  <v-container>
    <v-row>
      <v-col>
        <p
          class="text-h5 mb-0"
        >
          WIF-Editor
        </p>
        <v-divider
          class="mb-2"
        ></v-divider>
        <p
          class="font-italic"
        >
          Eine Browser-Anwendung zum Bearbeiten von WIF-Dateien. Unter aktiver Entwicklung!
        </p>
        <p
          class="text-h6 mt-4 mb-0"
        >
          Features
        </p>
        <v-divider
          class="mb-2"
        ></v-divider>
        <ul>
          <li>Import und Export von WIF-Dateien</li>
          <li>komplett mit Tastatur bedienbar</li>
          <li>läuft in den meisten aktuellen PC-Browsern unter Windows, Linux und macOS</li>
          <li>unter aktiver Entwicklung</li>
        </ul>
        <p
          class="text-h6 mt-4 mb-0"
        >
          Bisherige Einschränkungen
        </p>
        <v-divider
          class="mb-2"
        ></v-divider>
        <ul>
          <li>nicht für alle Webstühle geeignet</li>
          <li>jeweils nur eine Fadenstärke für Kette und Schuss</li>
          <li>bestimmt noch viel mehr, was ich im Moment nicht brauche :)</li>
        </ul>
        <p
          class="text-h6 mt-4 mb-0"
        >
          Screenshots
        </p>
        <v-divider
          class="mb-2"
        ></v-divider>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        Über die Seitenleiste gelangt man zu den verschiedenen Bereichen des WIF-Editors.
      </v-col>
      <v-col cols="9">
        <v-img
          :src="sidebarImage"
          max-width="512px"
          class="elevation-2"
        ></v-img>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        In der Projektverwaltung können neue Projekte erzeugt oder importiert werden sowie
        der aktuell zu bearbeitende Projekt ausgewählt oder exportiert werden werden.
      </v-col>
      <v-col cols="9">
        <v-img
          :src="projectsImage"
          max-width="512px"
          class="elevation-2"
        ></v-img>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        Unter den Einstellungen sind mehrere Kategorien zu finden. Unter "Allgemeines" kann man
        Informationen über die WIF-Datei hinterlegen.
      </v-col>
      <v-col cols="9">
        <v-img
          :src="settings1Image"
          max-width="512px"
          class="elevation-2"
        ></v-img>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        In der Kategorie "Webstuhl" wird die Anzahl der Schäfte und Tritte konfiguriert.
      </v-col>
      <v-col cols="9">
        <v-img
          :src="settings2Image"
          max-width="512px"
          class="elevation-2"
        ></v-img>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        In den Kategorien "Kette" und "Schuss" kann man die Eigenschaften wie Anzahl der Fäden
        und Fadendichte einstellen.
      </v-col>
      <v-col cols="9">
        <v-img
          :src="settings3Image"
          max-width="512px"
          class="elevation-2"
        ></v-img>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        Unter der Kategorie "Farben" können die für Kette und Schuss verwendeten Farben verwaltet werden.
      </v-col>
      <v-col cols="9">
        <v-img
          :src="settings5Image"
          max-width="512px"
          class="elevation-2"
        ></v-img>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        Den eigentlichen Editor bedient man (mit wenigen Ausnahmen) überwiegend mit der Tastatur. Die
        Arbeitsfläche ist in mehrere Bereiche aufgeteilt.
      </v-col>
      <v-col cols="9">
        <v-img
          :src="editorImage"
          max-width="512px"
          class="elevation-2"
        ></v-img>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <p>
          (1): Eine verkleinere Ansicht aller Kettfäden. Ein Rahmen zeigt an, welcher Ausschnitt
          im Editor gerade sichtbar ist.
        </p>
        <p>
          (2): Die Farbe der Kettfäden kann hier verändert werden.
        </p>
        <p>
          (3): Hier wird eingestellt, welcher Schaft für einen Kettfaden genommen wird.
        </p>
        <p>
          (4): Hier wird die Anbindung zwischen den Tritten und den Schäften konfiguriert.
        </p>
        <p>
          (5): Hier wird die Trittfolge eingestellt.
        </p>
      </v-col>
      <v-col cols="9">
        <v-img
          :src="editorAnnotatedImage"
          max-width="512px"
          class="elevation-2"
        ></v-img>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <p>
          (6): Die Farbe der Schussfäden kann hier verändert werden.
        </p>
        <p>
          (7): Eine verkleinerte Ansicht der Schussfäden. Ein Rahmen zeigt an, welcher Ausschnitt
          im Editor gerade sichtbar ist.
        </p>
        <p>
          (8): Eine Vorabansicht, wie das Webstück in der aktuellen Konfiguration aussehen könnte.
        </p>
        <p>
          (9): Die Nummer des aktuell ausgewählten Kett- oder Schussfadens.
        </p>
      </v-col>
      <v-col cols="9">
        <v-img
          :src="editorAnnotatedImage"
          max-width="512px"
          class="elevation-2"
        ></v-img>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        Die für die Editorbedienung notwendigen Tasten sind auf der Seite "Tastaturbelegung"
        zusammengefasst.
      </v-col>
      <v-col cols="9">
        <v-img
          :src="keyboardHelpImage"
          max-width="512px"
          class="elevation-2"
        ></v-img>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        Wenn das Muster vollständig eingegeben ist, findet man auf der Seite "Projektinformationen"
        eine Zusammenfassung der wichtigsten Eckdaten des Webstücks. Dazu zählen nach Farben gruppierte
        Fadenlauflängen, Litzeninformationen usw.
      </v-col>
      <v-col cols="9">
        <v-img
          :src="projectInfoImage"
          max-width="512px"
          class="elevation-2"
        ></v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import sidebarImage from '@/assets/WifEditor_Sidebar.png';
import projectsImage from '@/assets/WifEditor_Projects.png';
import settings1Image from '@/assets/WifEditor_Settings1.png';
import settings2Image from '@/assets/WifEditor_Settings2.png';
import settings3Image from '@/assets/WifEditor_Settings3.png';
import settings5Image from '@/assets/WifEditor_Settings5.png';
import editorImage from '@/assets/WifEditor_Editor.png';
import editorAnnotatedImage from '@/assets/WifEditor_EditorAnnotated.png';
import keyboardHelpImage from '@/assets/WifEditor_KeyboardHelp.png';
import projectInfoImage from '@/assets/WifEditor_ProjectInfo.png';

export default {
  data: () => ({
    sidebarImage,
    projectsImage,
    settings1Image,
    settings2Image,
    settings3Image,
    settings5Image,
    editorImage,
    editorAnnotatedImage,
    projectInfoImage,
    keyboardHelpImage,
  }),
};
</script>

<style>

</style>