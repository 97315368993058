<template>
  <canvas></canvas>
</template>

<script>
import {
  getPatternCanvasSize,
  parseTextualPattern,
  drawPattern,
} from 'maggydiy-common';

export default {
  props: [
    'pattern',
    'scale',
  ],

  mounted() {
    this.updatePattern();
  },

  watch: {
    pattern() {
      this.updatePattern();
    },
  },

  methods: {
    updatePattern() {
      const pattern = parseTextualPattern(this.pattern);

      const { width, height } = getPatternCanvasSize(pattern);

      const $canvas = this.$el;
      $canvas.width = width;
      $canvas.height = height;

      const ctx = $canvas.getContext('2d');

      ctx.clearRect(0, 0, width, height);

      drawPattern(ctx, pattern);
    },
  }
}
</script>

<style>

</style>