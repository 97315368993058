<template>
  <v-container fluid>
    <div v-html="htmlContent"></div>
  </v-container>
</template>

<script>
import marked from 'marked';
import { runAsync } from '@/utils';

export default {
  props: [
    'id',
  ],

  data: () => ({
    htmlContent: null,
  }),

  mounted() {
    runAsync(async () => {
      const response = await fetch(`/projects/${this.id}.md`);
      const markdown = await response.text();
      this.htmlContent = marked(markdown);
    });
  }
}
</script>

<style>

</style>