<template>
  <v-container>
    <div class="d-flex justify-center">
      <weaving-pattern :pattern="pattern"></weaving-pattern>
    </div>
    <div class="mt-4 d-flex justify-center">
      <router-link :to="{ name: 'PatternEditorWithData', params: { patternData } }">Muster im Editor öffnen</router-link>
    </div>
  </v-container>
</template>

<script>
import WeavingPattern from '@/components/WeavingPattern.vue';
import { decodePattern } from '@/utils';

export default {
  components: {
    WeavingPattern,
  },

  props: [
    'patternData',
  ],

  data: () => ({
    pattern: null,
  }),

  mounted() {
    this.pattern = decodePattern(this.patternData);
  },
}
</script>

<style>

</style>