<template>
  <v-app>
    <v-app-bar
      app
      color="green darken-3"
      dark
    >
      <div class="d-flex align-center">
        <span class="maggy-title">MaggyDIY</span>
      </div>

      <v-spacer></v-spacer>

      <v-btn
        href="https://twitch.tv/MaggyDIY"
        target="_blank"
        text
      >
        <v-icon>mdi-twitch</v-icon>
        <span class="mr-2">twitch.tv/MaggyDIY</span>
      </v-btn>
    </v-app-bar>

    <v-main class="grey lighten-3">
      <v-container fluid>
        <v-row>
          <v-col cols="2">
            <v-sheet rounded="lg">
              <v-list color="transparent">
                <v-list-item
                  v-for="link in links"
                  :key="link.id"
                  link
                  @click="linkClicked(link)"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ link.title }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-sheet>
          </v-col>

          <v-col>
            <v-sheet
              min-height="70vh"
              rounded="lg"
            >
              <router-view/>
            </v-sheet>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',

  components: {
  },

  data: () => ({
    links: [{
      id: 'home',
      title: 'Startseite',
      link: '/',
    }, {
      id: 'project',
      title: 'Aktuelles Projekt',
      link: '/project/20210522',
    }, {
      id: 'warpBoardEditor',
      title: 'Schärbrett-Editor',
      link: '/warp-board/',
    }, {
      id: 'patternEditor',
      title: 'Muster-Editor',
      link: '/edit-pattern',
    }, {
      id: 'rsc',
      title: 'Reed-Substitution-Rechner',
      link: '/rsc',
    }, {
      id: 'webTreter',
      title: 'Web-Treter',
      link: '/web-treter',
    }, {
      id: 'wifEditor',
      title: 'WIF-Editor',
      link: '/wif-editor',
    }, {
      id: 'imprint',
      title: 'Impressum',
      link: '/imprint',
    }],
  }),

  methods: {
    linkClicked(link) {
      this.$router.push(link.link);
    },
  },
};
</script>

<style scoped>
.maggy-title {
  font-family: 'Fredoka One';
  font-size: 30pt;
}
</style>