<template>
  <v-container fluid>
    <div class="text-h3 mb-5">Muster-Editor</div>
    <v-row>
      <v-col>
        <weaving-pattern :pattern="pattern" scale="10" />
      </v-col>
      <v-col>
        <v-card>
          <v-card-title>Eigenschaften</v-card-title>
          <v-card-text>
            <v-form>
              <v-slider
                v-model="shafts"
                label="Anzahl Schäfte"
                min="1"
                max="12"
                thumb-label
              ></v-slider>          
              <v-slider
                v-model="treadles"
                label="Anzahl Tritte"
                min="1"
                max="14"
                thumb-label
              ></v-slider>    
              <v-text-field
                v-model="threading"
                label="Threading"
              ></v-text-field>
              <v-text-field
                v-model="tieUp"
                label="Anbindung (Tie-up)"
              ></v-text-field>
              <v-text-field
                v-model="treadling"
                label="Trittfolge (Treadling)"
              ></v-text-field>
              <v-text-field class="mt-8" label="Share-Link" v-if="shareLink" v-model="shareLink" readonly></v-text-field>
              <v-btn block @click="downloadPdf">Als PDF speichern</v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import WeavingPattern from '../components/WeavingPattern.vue';
import {
  decodePattern,
  encodePattern,
  performJsonRpc,
  runAsync,
} from '@/utils';

export default {
  components: {
    WeavingPattern
  },

  props: [
    'patternData',
  ],

  data: () => ({
    shafts: 4,
    treadles: 4,
    threading: '4,3,2,1,3,4,1,2,3,4,1,2,4,3,2,1,3,4,1,2,3,4,1,2,4,3,2,1,3,4,1,2,3,4,1,2',
    tieUp: '1+2,2+3,3+4,1+4',
    treadling: '1,2,3,4,1,2,3,4,1,2,3,4,1,2,3,4,1,2,3,4,1,2,3,4,1,2,3,4,1,2,3,4,1,2,3,4',
  }),

  computed: {
    pattern() {
      const { shafts, treadles, threading, tieUp, treadling } = this;

      return {
        shafts,
        treadles,
        threading,
        tieUp,
        treadling,
      };
    },

    shareLink() {
      const patternData = encodePattern(this.pattern);
      const route = this.$router.resolve({
        name: 'PatternViewer',
        params: {
          patternData,
        },
      });
      const url = new URL(route.href, document.location);
      return url.toString();
    }
  },

  mounted() {
    if (this.patternData) {
      const { shafts, treadles, threading, tieUp, treadling } = decodePattern(this.patternData);
      this.shafts = shafts;
      this.treadles = treadles;
      this.threading = threading;
      this.tieUp = tieUp;
      this.treadling = treadling;
    }
  },

  methods: {
    share() {
      const patternData = encodePattern(this.pattern);
      console.log(patternData);
      const route = this.$router.resolve({
        name: 'PatternEditorWithData',
        params: {
          patternData,
        },
      });
      const url = new URL(route.href, document.location);
      this.shareLink = url;
    },

    downloadPdf() {
      runAsync(async () => {
        const { shafts, treadles, threading, tieUp, treadling, shareLink } = this;

        const result = await performJsonRpc('generatePatternPdf', {
          shafts,
          treadles,
          threading,
          tieUp,
          treadling,
          shareLink,
        });

        const elAnchor = document.createElement('a');
        elAnchor.href = result;
        elAnchor.target = '_blank';
        document.body.appendChild(elAnchor);
        elAnchor.click();
        elAnchor.remove();
      });
    },
  }

}
</script>

<style>

</style>