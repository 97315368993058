<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <div class="text-h3 mb-5">Schärbrett-Editor</div>

        <div class="text-h5 my-5">Einstellungen</div>
        <v-text-field v-model="data" label="Fadenweg" />
        <v-checkbox v-model="editMode" label="Zapfennummern anzeigen" />

        <div class="text-h5 my-5">Ergebnis</div>
        <canvas ref="canvas"></canvas>
        <div>Länge: {{length}} cm</div>
        <v-text-field class="mt-8" label="Share-Link" v-if="shareLink" v-model="shareLink" readonly></v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Victor from 'victor';

const pins = [];
for (let i = 0; i < 10; i++) {
  pins.push({
    x: 6.5 + 10 * i,
    y: 2.5,
    group: 1,
  });
}
for (let i = 0; i < 10; i++) {
  pins.push({
    x: 6.5 + 10 * i,
    y: 60.5,
    group: 2,
  });
}
for (let i = 0; i < 6; i++) {
  pins.push({
    x: 2.5,
    y: 6.5 + 10 * i,
    group: 3,
  });
}
for (let i = 0; i < 6; i++) {
  pins.push({
    x: 100.5,
    y: 6.5 + 10 * i,
    group: 4,
  });
}

// console.log(pins);

/* eslint-disable-next-line */
function rad2Deg(rad) {
  return (rad / Math.PI * 180);
}

function addArcForStep(path, step, radius) {
  const pin = pins [step.pinNr - 1];
  const startAngle = (step.entryAngle + 4 * Math.PI) % (2 * Math.PI);
  const endAngle = (step.exitAngle + 4 * Math.PI) % (2 * Math.PI);
  path.arc(pin.x, pin.y, radius, - startAngle, - endAngle, step.direction !== 'cw');

  const angleDiff = (step.direction !== 'cw') ? (endAngle - startAngle) : (startAngle - endAngle);
  const angle = (angleDiff + 4 * Math.PI) % (2 * Math.PI);
  // console.log(rad2Deg(startAngle), rad2Deg(endAngle), step.direction, rad2Deg(angleDiff), rad2Deg(angle));
  return (radius * angle);
}

export default {
  props: [
    'boardData',
  ],

  data: () => ({
    data: '',
    editMode: false,
    length: '???',
  }),

  computed: {
    shareLink() {
      const boardData = this.data;
      const route = this.$router.resolve({
        name: 'WarpBoardEditorWithData',
        params: {
          boardData,
        },
      });
      const url = new URL(route.href, document.location);
      return url.toString();
    },
  },

  mounted() {
    this.data = this.boardData || 'ccw10-8x6x1x28-29x22-24x30-31-18x31-30x24-22x29-28x1-6x8-10';
  },

  watch: {
    data() {
      this.updateBoard();
    },

    editMode() {
      this.updateBoard();
    }
  },

  methods: {
    updateBoard() {
      const data = this.data;

      const steps = [];
      let remaining = data;
      while (remaining) {
        const md = /^(cw|ccw)?(\d+)(?:([x-])(.*))?$/.exec(remaining);
        const direction = md [1];
        const pinNr = parseInt(md [2]);
        const connection = md [3];

        steps.push({
          direction,
          pinNr,
          connection,
        });

        remaining = md [4];
      }

      if (!steps [0].direction) {
        throw new Error('Invalid data, missing direction');
      }

      const isClosedLoop = (steps [0].pinNr === steps [steps.length - 1].pinNr);

      const stepCount = steps.length - 1;
      const stepModulo = isClosedLoop ? (steps.length - 1) : steps.length;

      const debug = false;

      const $canvas = this.$refs.canvas;
      const ctx = $canvas.getContext('2d');

      const width = 103 * 8;
      const height = 63 * 8;

      $canvas.width = width;
      $canvas.height = height;

      ctx.clearRect(0, 0, width, height);

      ctx.save();

      ctx.scale(8, 8);

      ctx.fillStyle = '#ffdead';
      ctx.fillRect(0, 0, 103, 5);
      ctx.fillRect(0, 58, 103, 5);
      ctx.fillRect(0, 0, 5, 63);
      ctx.fillRect(98, 0, 5, 63);

      ctx.font = '1pt Helvetica';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';

      const radius = 1;

      for (let i = 0; i < pins.length; i++) {
        const usedPin = steps.some(step => step.pinNr === (i + 1));

        const pin = pins [i];

        ctx.beginPath();
        ctx.arc(pin.x, pin.y, radius, 0, 2 * Math.PI);
        ctx.closePath();

        ctx.fillStyle = usedPin ? 'sienna' : 'burlywood';
        ctx.fill();

        if (debug || this.editMode) {
          ctx.fillStyle = usedPin ? 'white' : 'black';
          ctx.fillText(`${i + 1}`, pin.x, pin.y);
        }
      }

      const vRadius = new Victor(radius, 0);
      const vHalf = new Victor(0.5, 0.5);

      let direction = steps [0].direction;
      const path = new Path2D();
      let length = 0;

      for (let idx = 0; idx < stepCount; idx++) {
        const startStep = steps [idx];
        const endStep = steps [(idx + 1) % stepModulo];

        const startPin = pins [startStep.pinNr - 1];
        const endPin = pins [endStep.pinNr - 1];

        const vStart = new Victor(startPin.x, startPin.y);
        const vEnd = new Victor(endPin.x, endPin.y);
        const vStartToEnd = vEnd.clone().subtract(vStart);
        const angle = -vStartToEnd.horizontalAngle();

        let threadAngle;
        if (startStep.connection === '-') {
          // parrallel to vStartToEnd
          threadAngle = angle;
        } else {
          // crosses mid-point
          const vStartToMid = vStartToEnd.clone().multiply(vHalf);
          const startToMidLength = vStartToMid.length();

          const angle1 = Math.asin(radius / startToMidLength);
          // const angle2 = Math.PI / 2 - angle1;

          if (direction === 'cw') {
            threadAngle = angle - angle1;
          } else {
            threadAngle = angle + angle1;
          }
        }

        let exitAngle;
        if (direction === 'cw') {
          exitAngle = threadAngle + Math.PI / 2;
        } else {
          exitAngle = threadAngle - Math.PI / 2;
        }

        startStep.exitAngle = exitAngle;

        const exitPoint = vRadius.clone().rotate(- exitAngle).add(vStart);

        if (debug) {
          ctx.beginPath();
          ctx.arc(exitPoint.x, exitPoint.y, 0.3, 0, 2 * Math.PI);
          ctx.closePath();
          ctx.fillStyle = 'green';
          ctx.fill();
        }

        let entryAngle;
        if (startStep.connection === '-') {
          entryAngle = exitAngle;
        } else {
          entryAngle = (exitAngle + Math.PI) % (2 * Math.PI);
        }

        endStep.entryAngle = entryAngle;

        const entryPoint = vRadius.clone().rotate(- entryAngle).add(vEnd);

        if (debug) {
          ctx.beginPath();
          ctx.arc(entryPoint.x, entryPoint.y, 0.3, 0, 2 * Math.PI);
          ctx.closePath();
          ctx.fillStyle = 'red';
          ctx.fill();
        }

        if (startStep.connection === 'x') {
          if (direction === 'cw') {
            direction = 'ccw';
          } else {
            direction = 'cw';
          }
        }

        if (!endStep.direction) {
          endStep.direction = direction;
        } else if (endStep.direction === direction) {
          // nop
        } else if (isClosedLoop) {
          throw new Error(`Invalid data, direction mismatch on step ${idx + 1}`);
        }

        const vExitToEntry = entryPoint.clone().subtract(exitPoint);
        length += vExitToEntry.length();

        if (idx === 0) {
          path.moveTo(exitPoint.x, exitPoint.y);
        } else {
          length += addArcForStep(path, startStep, radius);
        }

        path.lineTo(entryPoint.x, entryPoint.y);
      }

      if (isClosedLoop) {
        length += addArcForStep(path, steps [0], radius);
        path.closePath();

        length = length / 2;
      } else {
        length += Math.PI;
      }

      ctx.lineWidth = 0.2;
      ctx.strokeStyle = 'blue';
      ctx.stroke(path);

      ctx.restore();

      this.length = length.toFixed(1);
    }
  },
}
</script>

<style>

</style>