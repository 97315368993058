import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Imprint from '../views/Imprint.vue';
import PatternDatabase from '../views/PatternDatabase.vue';
import PatternEditor from '../views/PatternEditor.vue';
import PatternViewer from '../views/PatternViewer.vue';
import ProjectViewer from '../views/ProjectViewer.vue';
import ReedSubstitutionCalculator from '../views/ReedSubstitutionCalculator.vue';
import WarpBoardEditor from '../views/WarpBoardEditor.vue';
import WebTreter from '../views/WebTreter.vue';
import WifEditor from '../views/WifEditor.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/pattern/:patternId',
    name: 'PatternDatabase',
    component: PatternDatabase,
    props: true,
  },
  {
    path: '/view-pattern/:patternData',
    name: 'PatternViewer',
    component: PatternViewer,
    props: true,
  },
  {
    path: '/edit-pattern',
    name: 'PatternEditor',
    component: PatternEditor
  },
  {
    path: '/edit-pattern/:patternData',
    name: 'PatternEditorWithData',
    component: PatternEditor,
    props: true,
  },
  {
    path: '/warp-board/',
    component: WarpBoardEditor,
  },
  {
    path: '/warp-board/:boardData',
    name: 'WarpBoardEditorWithData',
    component: WarpBoardEditor,
    props: true,
  },
  {
    path: '/project/:id',
    component: ProjectViewer,
    props: true,
  },
  {
    path: '/rsc',
    component: ReedSubstitutionCalculator,
    props: true,
  },
  {
    path: '/web-treter',
    component: WebTreter,
  },
  {
    path: '/wif-editor',
    component: WifEditor,
  },
  {
    path: '/imprint',
    component: Imprint,
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
];

const router = new VueRouter({
  routes
});

export default router;
