<template>
  <v-container>
    <div class="d-flex justify-center">
      <weaving-pattern :pattern="pattern"></weaving-pattern>
    </div>
    <div class="mt-4 d-flex justify-center">
      <router-link :to="{ name: 'PatternEditorWithData', params: { patternData } }">Muster im Editor öffnen</router-link>
    </div>
  </v-container>
</template>

<script>
import WeavingPattern from '@/components/WeavingPattern.vue';
import { encodePattern } from '@/utils';

const patternById = new Map([
  [ 'No8Kxh', {
    shafts: 4,
    treadles: 4,
    threading: '4,3,2,1,3,4,1,2,3,4,1,2,4,3,2,1,3,4,1,2,3,4,1,2,4,3,2,1,3,4,1,2,3,4,1,2',
    tieUp: '1+2,2+3,3+4,4+1',
    treadling: '1,2,3,4,1,2,3,4,1,2,3,4,1,2,3,4,1,2,3,4,1,2,3,4,1,2,3,4,1,2,3,4,1,2,3,4',
  } ],
  [ '3zTaEt', {
    shafts: 4,
    treadles: 4,
    threading: '4,3,2,1,3,4,1,2,3,4,1,2,4,3,2,1,3,4,1,2,3,4,1,2,4,3,2,1,3,4,1,2,3,4,1,2',
    tieUp: '1+2,2+3,3+4,4+1',
    treadling: '1,2,3,4,2,1,4,3,2,1,4,3,1,2,3,4,2,1,4,3,2,1,4,3,1,2,3,4,2,1,4,3,2,1,4,3',
  } ],
  [ 'JaX77J', {
    shafts: 4,
    treadles: 4,
    threading: '4,3,2,1,3,4,1,2,3,4,1,2,4,3,2,1,3,4,1,2,3,4,1,2,4,3,2,1,3,4,1,2,3,4,1,2',
    tieUp: '1+2,2+3,3+4,4+1',
    treadling: '1,2,3,4,3,2,1,2,3,4,3,2,1,2,3,4,3,2,1,2,3,4,3,2,1,2,3,4,3,2,1,2,3,4,3,2',
  } ],
]);

export default {
  components: {
    WeavingPattern,
  },

  props: [
    'patternId',
  ],

  data: () => ({
    pattern: null,
    patternData: null,
  }),

  mounted() {
    this.pattern = patternById.get(this.patternId);
    this.patternData = encodePattern(this.pattern);
  },
};
</script>

<style>

</style>