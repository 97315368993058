import LZString from 'lz-string';

export function encodePattern(pattern) {
  const jsonPattern = JSON.stringify(pattern);
  const compressedBase64 = LZString.compressToEncodedURIComponent(jsonPattern);
  // const uncompressedBase64 = Buffer.from(jsonPattern).toString('base64');
  // console.log(`Uncompressed: ${uncompressedBase64.length}, compressed: ${compressedBase64.length}`);
  return compressedBase64;
}

export function decodePattern(compressedBase64) {
  const jsonPattern = LZString.decompressFromEncodedURIComponent(compressedBase64);
  const pattern = JSON.parse(jsonPattern);
  return pattern;
}

export function runAsync(fn) {
  async function run() {
    await fn();
  }

  run().then(null, err => {
    console.log(err);
  });
}

let nextJsonRpcId = 1;

export async function performRawJsonRpcs(calls) {
  const requests = calls.map(call => {
    const id = nextJsonRpcId;
    nextJsonRpcId += 1;

    return {
      jsonrpc: '2.0',
      id,
      method: call.method,
      params: call.params,
    };
  });

  const httpResponse = await fetch('/api/v1/jsonrpc', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(requests),
  });

  const responses = await httpResponse.json();

  return responses;
}

export async function performJsonRpcs(calls) {
  const responses = await performRawJsonRpcs(calls);

  for (const response of responses) {
    if (response.error) {
      throw new Error(response.error.message);
    }
  }

  return responses.map(response => response.result);
}

export async function performJsonRpc(method, params) {
  const results = await performJsonRpcs([{ method, params }]);

  return results [0];
}
