<template>
  <v-container>
    <div class="text-h3 mb-5">Willkommen auf der Homepage von @MaggyDIY!</div>
  </v-container>
</template>

<script>
export default {
  name: 'Home',

  data: () => ({
  }),

  components: {
  },

  mounted() {
  },

  methods: {
  }
}
</script>
